<template>
  <el-row :gutter="20" style="margin-top: 10px; flex: 1">
    <el-col :span="4">
      <el-select
        v-model="modelId"
        placeholder="模型选择"
        @change="handleSelectChange"
      >
        <el-option
          v-for="item in modelList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-select
        v-model="prodId"
        placeholder="产品选择"
        @change="handleSelectChange"
      >
        <el-option
          v-for="item in prodList"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-select
        v-model="brandId"
        placeholder="品牌选择"
        @change="handleSelectChange"
      >
        <el-option
          v-for="item in brandList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FilterSelect",
  computed: {
    ...mapState(["modelList", "brandList"]),
  },
  data() {
    return {
      modelId: null,
      prodId: null,
      brandId: null,
      prodList: [],
    };
  },
  created() {
    this.handleInit();
  },
  methods: {
    async handleInit() {
      await this.$store.dispatch("getGoodsModels");
      await this.$store.dispatch("getAllBrandList");
      this.modelId = this.modelList[0].id;
      this.brandId = this.brandList[0].id;
      this.prodList = await this.$store.dispatch("getModelFindProd", {
        model_id: this.modelId,
      });
      this.prodId = this.prodList[0].id;
      this.handleSelectChange();
    },
    handleSelectChange() {
      this.$emit("change", {
        modelId: this.modelId,
        prodId: this.prodId,
        brandId: this.brandId,
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
