
<template>
  <div class="wrapper1">
    <filter-select @change="handleSelectChange"></filter-select>
    <list
      :list="inventoryList"
      @addMaterialInventory="handleAddMaterialInventoryParam"
      @addSpecInventory="handleAddSpecInventoryParam"
    ></list>
    <add-store
      :show="show"
      :modelId="modelId"
      :type="addType"
      :materialId="materialId"
      :materialList="materialList"
      :title="title"
      :specId="specId"
      :supId="supId"
      :specList="inventoryList"
      :isLiberty="isLiberty"
      @visible="handleShowChange"
      @comfirm="handleAddInventoryComfirm"
    ></add-store>
  </div>
</template>

<script>
import FilterSelect from "./components/FilterSelect";
import List from "./components/List";
import AddStore from "./components/AddStore";
export default {
  name: "storeManage",
  components: {
    FilterSelect,
    List,
    AddStore,
  },
  props: {},
  data() {
    return {
      title: "产品入库",
      addType: "material",
      isLiberty: false,
      show: false,
      modelId: null,
      specId: null,
      supId: null,
      materialId: null,
      materialList: [],
      inventoryList: [],
    };
  },
  watch: {
    modelId() {
      this.getMaterials();
    },
  },
  computed: {
    isShowFilters() {
      return this.modelId != "" &&
        this.typeListSelect.length > 0 &&
        this.stuffListSelect.length > 0
        ? true
        : false;
    },
  },
  methods: {
    //筛选改变
    handleSelectChange(param) {
      this.modelId = param.modelId;
      this.prodId = param.prodId;
      this.brandId = param.brandId;
      this.getInventoryList();
    },
    //获取库存列表
    async getInventoryList() {
      const stocks = await this.$store.dispatch("getInventoryList", {
        model_id: this.modelId,
        goods_id: this.prodId,
        spec_filter: JSON.stringify({
          brand_id: this.brandId,
        }),
      });
      this.inventoryList = stocks.map((item, index) => {
        return {
          specId: item.spec_id,
          typeName: item.attrs.type_id,
          materials: Object.values(item.materials),
        };
      });
    },

    //获取原材料列表
    async getMaterials() {
      this.materialList = await this.$store.dispatch("getMaterials", {
        model_id: this.modelId,
      });
    },
    //添加材料库存
    handleAddMaterialInventoryParam(param) {
      this.materialId = param.materialId;
      this.specId = param.specId;
      this.supId = param.supId;
      this.show = true;
      this.isLiberty = false;
      this.addType = "material";
      this.title = "原材料入库";
    },
    //添加规格对应原材料库存
    handleAddSpecInventoryParam(param) {
      this.specId = param.specId;
      this.supId = param.supId;
      this.show = true;
      this.isLiberty = false;
      this.addType = "spec";
      this.title = "产品入库";
    },
    //提交
    handleAddInventoryComfirm(param) {
      switch (param.type) {
        case "spec":
          this.handleAddSpecInventoryComfirm(param);
          break;
        case "material":
          this.handleAddMaterialInventoryComfirm(param);
          break;

        default:
          break;
      }
    },
    //添加规格对应原材料库存提交
    handleAddSpecInventoryComfirm(param) {
      this.$store
        .dispatch("addSpecInventory", {
          goods_stocks: JSON.stringify([
            {
              spec_id: param.specId,
              sup_id: param.supId,
              num: Number(param.num),
            },
          ]),
        })
        .then(this.handleSucc);
    },
    //添加材料库存提交
    handleAddMaterialInventoryComfirm(param) {
      this.$store
        .dispatch("addMaterialInventory", {
          materials_stocks: JSON.stringify([
            {
              material_id: param.materialId,
              spec_id: param.specId,
              sup_id: param.supId,
              num: Number(param.num),
            },
          ]),
        })
        .then(this.handleSucc);
    },
    handleSucc(res) {
      if (res.code === 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.show = false;
        this.getInventoryList();
      }
    },
    //显示隐藏弹窗
    handleShowChange(e) {
      this.show = e;
    },
    //打开添加入库面板
    openAddStore() {
      this.show = true;
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper1 {
  width: 100%;
  height: 100%;
  font-size: 12px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .el-col-20 {
    margin-top: 10px;
  }
  /deep/.button-new-tag {
    padding-top: 0;
    height: 30px !important;
    padding: 0 10px !important;
    margin-left: 10px;
  }
  .input-new-tag {
    width: 50px;
    height: 30px !important;
    padding: 0 10px !important;
    margin: 0 !important;
    margin-left: 10px;
    /deep/.el-input {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
