<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="65%">
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="5">
        <el-select
          :disabled="!isLiberty"
          v-model="materialId"
          placeholder="原材料选择"
          v-if="type === 'material'"
        >
          <el-option
            v-for="item in materialList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="type === 'material' ? 5 : 6">
        <el-select
          :disabled="!isLiberty"
          v-model="specId"
          placeholder="规格选择"
        >
          <el-option
            v-for="item in specList"
            :key="item.specId"
            :label="item.typeName"
            :value="item.specId"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="type === 'material' ? 5 : 6">
        <el-select
          :disabled="!isLiberty"
          v-model="supId"
          placeholder="供应商选择"
        >
          <el-option
            v-for="item in supplierList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="type === 'material' ? 5 : 6">
        <el-input
          type="text"
          v-model="num"
          :minlength="1"
          placeholder="库存数量"
        ></el-input>
      </el-col>
      <el-col :span="type === 'material' ? 4 : 6">
        <el-button type="primary" @click="addStoreByMaterial"
          >添加入库</el-button
        >
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "AddStore",
  props: {
    title: String,
    show: Boolean,
    modelId: Number,
    specId: Number,
    supId: Number,
    specList: Array,
    materialId: Number,
    materialList: Array,
    type: {
      type: String,
      default: "spec",
    },
    isLiberty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      num: null,
    };
  },
  computed: {
    ...mapState(["supplierList"]),
  },
  watch: {
    show(e) {
      this.dialogVisible = e;
    },
    dialogVisible(e) {
      this.$emit("visible", e);
    },
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    async handleInit() {
      await this.$store.dispatch("getAllSuppliers");
    },
    //添加入库
    addStoreByMaterial() {
      this.$emit("comfirm", {
        type: this.type,
        materialId: this.materialId,
        specId: this.specId,
        supId: this.supId,
        num: this.num,
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style> 