<template>
  <table-container :list="list" height="750">
    <el-table-column type="index" label="序号" fixed> </el-table-column>
    <el-table-column prop="typeName" label="型号" fixed width="100">
    </el-table-column>
    <el-table-column prop="supplier_20" label="原材料" fixed>
      <template slot-scope="scope">
        <div
          class="item"
          v-for="item of scope.row.materials"
          :key="item.stock_key"
        >
          {{ item.name }}
        </div>
      </template>
    </el-table-column>

    <el-table-column prop="lastmonth_usage" label="剩余库存" align="center">
      <template v-for="supItem of supplierList">
        <el-table-column
          :key="supItem.id"
          prop="supplier_20"
          :label="supItem.name"
        >
          <template slot-scope="scope">
            <div class="item-container">
              <div class="item-left">
                <div
                  class="item"
                  :class="
                    supNum(supItem.id, item.suppliers) ? 'supItem' : 'disable'
                  "
                  v-for="item of scope.row.materials"
                  :key="item.stock_key"
                  @click="
                    handleAddMaterialClick(
                      supNum(supItem.id, item.suppliers),
                      supItem.id,
                      item.material_id,
                      scope.row.specId
                    )
                  "
                >
                  {{ supItem.id | supNum(item.suppliers)
                  }}<i
                    class="el-icon-plus iconfont"
                    v-if="supNum(supItem.id, item.suppliers)"
                  ></i>
                </div>
              </div>
              <div
                class="item-right"
                v-if="supNum(supItem.id, scope.row.materials[0].suppliers)"
                @click="handleAddSpecClick(supItem.id, scope.row.specId)"
              >
                <i class="el-icon-circle-plus-outline"></i>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table-column>
    <el-table-column prop="curmonth_usage" label="使用情况" align="center">
      <el-table-column prop="7days_usage" label="上月使用">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="item of scope.row.materials"
            :key="item.stock_key"
          >
            {{ item.attrs.lastmonth_usage }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="7days_usage" label="本月使用">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="item of scope.row.materials"
            :key="item.stock_key"
          >
            {{ item.attrs.curmonth_usage }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="7days_usage" label="近7天使用">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="item of scope.row.materials"
            :key="item.stock_key"
          >
            {{ item.attrs.latest_7days_usage }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="today_usage" label="今日使用">
        <template slot-scope="scope">
          <div
            class="item"
            v-for="item of scope.row.materials"
            :key="item.stock_key"
          >
            {{ item.attrs.today_usage }}
          </div>
        </template>
      </el-table-column>
    </el-table-column>
  </table-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "List",
  props: {
    list: Array,
  },
  computed: {
    ...mapState(["supplierList"]),
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    async handleInit() {
      await this.$store.dispatch("getAllSuppliers");
    },
    //添加对应原材料库存
    handleAddMaterialClick(status, supId, materialId, specId) {
      if (status) {
        this.$emit("addMaterialInventory", { supId, materialId, specId });
      }
    },
    //添加对应规格的原材料库存
    handleAddSpecClick(supId, specId) {
      this.$emit("addSpecInventory", { supId, specId });
    },
    //筛选展示库存
    supNum(id, list) {
      const item = Object.values(list).find((item) => {
        return id === item.sup_id;
      });
      return item ? true : false;
    },
  },
  filters: {
    //筛选展示库存
    supNum(id, list) {
      const item = Object.values(list).find((item) => {
        return id === item.sup_id;
      });
      return item ? item.stock_num : "";
    },
  },
};
</script>
<style lang="less" scoped>
.item-container {
  // height: 40px;
  padding: 0 10px;
  margin: 0 -10px;
  // margin-left: -10px;
  display: flex;
  .item-left {
    flex: 1 1 auto;
    // margin-right: 10px;
  }
  .item-right {
    cursor: pointer;
    margin-right: -10px;
    width: 40%;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #ebeef5;
    box-sizing: border-box;
    &:hover {
      transition: all 0.25s ease;
      background-color: #409eff;
      color: #fff;
    }
    i {
      font-size: 16px;
    }
  }
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  margin: 0 -10px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  line-height: 20px;

  &.disable {
    background-color: #e7e7e7;
  }
  &:last-child {
    border: none;
  }
  .iconfont {
    margin-left: 5px;
  }
}
.supItem {
  cursor: pointer;
  margin-right: 0;

  &:hover {
    transition: all 0.25s ease;
    background-color: #409eff;
    color: #fff;
  }
}
</style>